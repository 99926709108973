<!-- workflow3の申請経路の設定用-->
<template>
  <div>
    <template>
      <v-row v-for="(item, index) in mapRoutes" :key="index" class="d-flex align-center py-1" no-gutters>
        <v-col cols="12"><v-divider class="pa-2" /> </v-col>

        <v-col cols="1">
          <v-toolbar-title class="pl-5">{{ item.row }}</v-toolbar-title>
        </v-col>
        <v-col cols="5">
          <v-text-field label="経路名" v-model="item.disp_name" prepend-icon="mdi-subtitles-outline" />
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="人数"
            type="number"
            v-model="item.accept_count"
            suffix="人"
            class="right-input"
            prepend-icon="mdi-account-group"
            :outlined="outlined"
            :dense="dense"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="1" />

        <v-col cols="2">
          <v-btn @click="btnExpandClick(index, true)" v-if="!item.expand" small
            ><v-icon>mdi-chevron-down</v-icon>展開</v-btn
          >
          <v-btn @click="btnExpandClick(index, false)" v-if="item.expand" small
            ><v-icon>mdi-chevron-up</v-icon>畳む</v-btn
          >
        </v-col>
        <v-col cols="1" v-if="!item.expand" class="pt-0 pb-2" />
        <v-col cols="11" v-if="!item.expand" class="pt-0 pb-2">
          <v-select
            :prepend-icon="useIcon ? 'mdi-account-circle' : ''"
            :outlined="outlined"
            :dense="dense"
            :label="'対象ユーザー'"
            :items="getUsersList()"
            item-text="disp_name"
            item-value="id"
            multiple
            readonly
            v-model="item.route"
          />
        </v-col>
        <v-col cols="8" v-if="item.expand" class="pt-0 pb-2" />
        <v-col cols="4" v-if="item.expand" class="pt-0 pb-2">
          <v-btn @click="btnSetSenderClick(index)" small>≪申請者≫をセット</v-btn>
        </v-col>
        <v-col cols="12" v-if="item.expand" class="pt-0 pb-2">
          <multi-user-select-list :data="item.route" :label="'対象ユーザー'" :dispNumber="false" />
        </v-col>
        <template>
          <v-col cols="1" />
          <v-col cols="3">
            <v-btn @click="btnUpItemClick(index)" small><v-icon>mdi-arrow-up-bold</v-icon></v-btn>
            <v-btn @click="btnDownItemClick(index)" small><v-icon>mdi-arrow-down-bold</v-icon></v-btn>
          </v-col>
          <v-col cols="3">
            <v-text-field label="承認時名称" v-model="item.accept_name" prepend-icon="mdi-check-circle-outline" />
          </v-col>
          <v-col cols="3">
            <v-text-field label="却下時名称" v-model="item.reject_name" prepend-icon="mdi-minus-circle-outline" />
          </v-col>
          <v-col cols="2">
            <v-btn color="warning" @click="btnDeleteItemClick(index)" small><v-icon>mdi-delete</v-icon>削除</v-btn>
          </v-col>
        </template>
      </v-row>
      <v-row class="d-flex align-center py-1" no-gutters>
        <v-col cols="12"><v-divider class="pa-2" /> </v-col>
        <v-col cols="1">
          <v-btn color="info" @click="btnAddItemClick()" fab x-small dark><v-icon dark>mdi-plus</v-icon></v-btn>
        </v-col>
        <v-col cols="11" />
      </v-row>
    </template>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import MultiUserSelectList from './MultiUserSelectList.vue';

export default {
  components: {
    MultiUserSelectList
  },
  name: 'WF3RouteSetting',
  props: {
    value: {},
    readonly: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    useIcon: {
      type: Boolean,
      default: true
    },
    useTitleLabel: {
      type: Boolean,
      default: false
    },
    ismobile: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      mapRoutes: [],
      disp_col: 4,
      data_col: 8
    };
  },
  computed: {
    ...mapState({
      user: state => state.user,
      usersList: state => state.user.usersList,
      filedata: state => state.filedata.filedata,
      userconfig: state => state.userConfig.userconfig
    })
  },

  created: function () {},
  mounted: function () {
    if (this.value) {
      this.mapRoutes.splice(0);

      //初期選択のuser
      this.mapRoutes.push(...this.value);
    }
  },
  watch: {
    value: function () {
      this.mapRoutes.splice(0);

      //初期選択のuser
      if (this.value) {
        this.mapRoutes.push(...this.value);
      }
    },

    ismobile(val) {
      this.disp_col = val ? 12 : 4;
      this.data_col = val ? 12 : 8;
    }
  },
  methods: {
    ...mapActions([]),
    onResize() {},
    inputCheck() {
      let errorMessages = [];

      errorMessages.push(
        ...this.mapRoutes.filter(x => x.route.length == 0).map(x => `経路 ${x.row}行目:承認者を入力してください。`)
      );

      errorMessages.push(
        ...this.mapRoutes
          .filter(x => x.accept_count > x.route.length && x.route.length > 0)
          .map(x => `経路 ${x.row}行目:承認者数より承認人数が多いです。`)
      );
      errorMessages.push(
        ...this.mapRoutes.filter(x => x.disp_name.length === 0).map(x => `経路 ${x.row}行目:経路名を入力してください。`)
      );

      return errorMessages;
    },
    btnExpandClick(index, expand) {
      const mapRoutes = [...this.mapRoutes];
      if (expand) {
        mapRoutes.forEach((x, i) => (x.expand = i == index));
      } else {
        mapRoutes[index].expand = false;
      }
      this.mapRoutes = mapRoutes;
    },
    btnSetSenderClick(index) {
      const mapRoutes = [...this.mapRoutes];
      if (!mapRoutes[index].route.some(x => x === -1004)) {
        mapRoutes[index].route = [-1004, ...(mapRoutes[index].route ?? [])];
      }
      this.mapRoutes = mapRoutes;
    },
    btnUpItemClick(index) {
      const mapRoutes = [...this.mapRoutes];
      if (index > 0) {
        [mapRoutes[index - 1], mapRoutes[index]] = [mapRoutes[index], mapRoutes[index - 1]];
        this.mapRoutes = mapRoutes.map((x, i) => ({ ...x, row: i + 1 }));
      }
      this.value.splice(0);
      this.value.push(...this.mapRoutes);
    },

    btnDownItemClick(index) {
      const mapRoutes = [...this.mapRoutes];
      if (index < mapRoutes.length - 1) {
        [mapRoutes[index], mapRoutes[index + 1]] = [mapRoutes[index + 1], mapRoutes[index]];
        this.mapRoutes = mapRoutes.map((x, i) => ({ ...x, row: i + 1 }));
      }
      this.value.splice(0);
      this.value.push(...this.mapRoutes);
    },

    btnDeleteItemClick(index) {
      const mapRoutes = [...this.mapRoutes];
      mapRoutes.splice(index, 1);
      this.mapRoutes = mapRoutes.map((x, i) => ({ ...x, row: i + 1 }));
      this.value.splice(0);
      this.value.push(...this.mapRoutes);
    },

    btnAddItemClick() {
      const mapRoutes = [...this.mapRoutes];
      mapRoutes.push({
        route_id: -1,
        row: -1,
        disp_name: '経路',
        route: [],
        accept_count: 0,
        accept_name: '承認',
        reject_name: '否決',
        expand: false
      });
      this.mapRoutes = mapRoutes.map((x, i) => ({ ...x, row: i + 1 }));
      this.value.splice(0);
      this.value.push(...this.mapRoutes);
      /*
  route_id     Int // workflow_route.idを指定する(PK)
  row          Int // 経路順番(PK)
  disp_name    String @db.VarChar // 表示名
  route        Int[] // 申請対象のユーザー
  accept_count Int // 必要な承認人数
*/
    },
    getUsersList() {
      return [...this.usersList, { id: -1004, disp_name: '≪申請者≫', enable: true }];
    }
  }
};
</script>
